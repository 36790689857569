/* eslint-disable no-console */
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('hotels', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`hotels/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`hotels/${id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('hotels', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLogo(ctx, { id, updateLogoDto }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`hotels/${id}/logo`, updateLogoDto)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRestaurants(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('restaurants', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchQRLinks(ctx, { hotel, rooms }) {
      console.log(hotel, rooms)
      return new Promise((resolve, reject) => {
        axios
          .get(`hotels/generatePDF/${hotel}/${rooms}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    updateHotelRestaurants(ctx, { id, updateHotelRestaurantsDto }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`hotels/${id}/restaurants`, updateHotelRestaurantsDto)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
