<template>
  <div>
    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Dine In Restaurants" label-for="dine-in-restaurants">
            <v-select
              id="dine-in-restaurants"
              v-model="selectedDineIn"
              variant="dark"
              :noDrop="readOnly"
              :options="restaurants"
              label="name"
              :reduce="restaurant => restaurant._id"
              multiple
            />
          </b-form-group>
        </b-col>

        <!-- Field: Birth Date -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Nearby Restaurants" label-for="nearby-restaurants">
            <v-select
              id="nearby-restaurants"
              v-model="selectedRestaurants"
              variant="success"
              :noDrop="readOnly"
              :options="restaurants"
              label="name"
              :reduce="restaurant => restaurant._id"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="!readOnly" class="mt-2">
        <b-col>
          <b-button
            variant="dark"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="
              updateHotelRestaurants({
                dinein: selectedDineIn,
                restaurants: selectedRestaurants,
              })
            "
          >
            <b-spinner v-if="updating" style="margin:1px 3px" small />
            Save Changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner, BForm, BFormGroup, BButton } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BForm,
    vSelect,
    BFormGroup,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    restaurants: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selectedDineIn: [],
      selectedRestaurants: [],
      updating: false,
    }
  },
  watch: {
    userData: {
      deep: true,
      immediate: true,
      handler(hotel) {
        if (hotel.dinein) {
          if (!this.readOnly)
            // eslint-disable-next-line no-underscore-dangle
            this.selectedDineIn = hotel.dinein.map(restaurant => restaurant._id)
          else this.selectedDineIn = hotel.dinein
        } else this.selectedDineIn = []

        if (hotel.restaurants) {
          if (!this.readOnly)
            // eslint-disable-next-line no-underscore-dangle
            this.selectedRestaurants = hotel.restaurants.map(restaurant => restaurant._id)
          else this.selectedRestaurants = hotel.restaurants
        } else this.selectedRestaurants = []
      },
    },
  },
  methods: {
    updateHotelRestaurants(updateHotelRestaurantsDto) {
      this.updating = true
      store
        .dispatch('app-user/updateHotelRestaurants', {
          id: router.currentRoute.query.id,
          updateHotelRestaurantsDto,
        })
        .then(response => {
          this.updating = false
          if (response.data.type === 'success') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'saved successfully!',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Failed to save',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.display,
              },
            })
          }
        })
        .catch(error => {
          this.updating = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Failed to save',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
    },
  },
  setup() {
    const userDataInfo = ref({
      dob: null,
      mobile: '+6595895857',
      website: 'https://rowboat.com/insititious/Angelo',
      language: 'French',
      gender: 'female',
      contactOptions: ['Email', 'Message'],
      addressLine1: 'A-65, Belvedere Streets',
      addressLine2: '',
      postcode: '',
      city: 'New York',
      state: '',
      country: '',
    })

    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ]

    const contactOptionsOptions = ['Email', 'Message', 'Phone']

    return {
      userDataInfo,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
